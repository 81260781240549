export default {
  marginRightText: '1rem',
  topHeadlineMargin: '2rem',
  topPaddingDesktop: 'none',
  topPaddingMobile: '2rem',
  liFontSize: '1rem',
  color: 'var(--vegas-gold-400)',
  textColor: 'var(--black)',
  bulletColor: '#000',
  titleColor: 'var(--vegas-gold-400)',
  tableDisplay: 'block',
  tableAlign: 'center',
  tableHeaderColor: '#fff',
  tableHeaderBackgroundColor: 'var(--rahoitu-orange-500)',
  tablePadding: '5px',
  tableRowColorOne: '#eee',
  asideBackgroundColor: 'var(--rahoitu-orange-500)',
};
