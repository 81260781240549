export default {
  mobileMenuBg: 'yellow',
  mobileMenuButtonColor: 'var(--vegas-gold-550)',
  menuMarginTopMobile: '0.7rem',
  textBgColor: 'var(--black)',
  buttonSvgWidthHeight: '1.5rem',
  backgroundColor: 'var(--vegas-gold-50)',
  linkColor: 'var(--black)',
  fontFamily: 'var(--fontFamily)',
  overlayBackgroundColor: 'var(--vegas-gold-50)',
  activeLinkColor: 'var(--mbc)',
  loginButtonBackgroundColor: 'var(--mbc)',
  loginButtonTextColor: 'var(--black)',
  loginButtonBorderRadius: '30px',
  loginIconColor: 'var(--black)',
  phoneNumberIconColor: 'var(--black)',
};
