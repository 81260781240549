export default {
  borderRadius: '1rem',
  fontWeight: '600',
  textFontWeight: '400',
  cardBackground: 'white',
  textColor: 'black',
  linkCardArrowColor: 'var(--vegas-gold-400)',
  linkArrowSize: '1.5',
  cardTitleColor: 'var(--link-card-title-color)',
};
