export default {
  questionColor: 'black',
  borderRadius: '1rem',
  backgroundColorFAQ: 'var(--white)',
  margin: '1.5rem 0',
  boxShadow: '0 1px 3px rgba(0, 0, 0, .25)',
  questionPadding: '1rem',
  questionJustification: 'space-between',
  answerPadding: '0.5rem 1rem 1rem',
  bulletColor: '#000',
  order: '1',
  FAQAnswerMargin: '0',
  fontSize: '1.5rem',
  letterSpacing: 'var(--has-no-letter-spacing)',
  expandButtonBorderRadius: '28px',
  borderWidth: '2px',
  borderColor: 'var(--black)',
  expandButtonMargin: 'auto',
  paddingTop: '-2rem',
  titleSizeDownLaptop: '1.6rem',
  buttonBackground: 'var(--transparent)',
  borderStyle: 'solid',
  transformBorder: '2px solid var(--rahoitu-orange-500)',
  detailsBorderRadius: '1rem',
  detailsIconColor: 'var(--rahoitu-orange-500)',
  answerFontWeight: 'var(--has-no-font-weight)',
  openQuestionColor: 'var(--rahoitu-orange-500)',
  svgTransform: '0deg',
  svgTransformOpen: 'rotate(180deg)',
  svgTransition: '0.2s ease 0s',
  svgOrder: '1',
  svgHeight: '1.5rem',
  svgMarginRight: '1rem',
  svgColor: 'var(--vegas-gold-450)',
  svgColorOpen: 'var(--mbc)',
  answerFontSize: '1rem',
};
