export default {
  cardBorderRadius: '1rem',
  expandButtonWidth: '18rem',
  expandButtonMargin: '0 auto',
  expandButtonBorderRadius: '1000px',
  expandButtonBorder: '2px solid #000',
  h2FontSize: '1.5rem',
  height: '12rem',
  borderRadius: '5px',
  displayPostTitle: 'flex',
  display: 'none',
  postDescriptionHeight: '8rem',
  bottom: '1.5rem',
  transform: 'scaleX(0.75)',
  postTitlePadding: '0 1.5rem 0',
  postTitleColor: 'var(--black)',
  postsMargin: '0',
  displayActionsWrapper: 'none',
  postDescriptionBackgroundColor: 'white',
  justifyContent: 'center',
  textAlign: 'center',
};
