export default {
  fill: '%23df202d',
  uspJustifyContent: 'center',
  marginLeft: '0',
  lineHeight: '1.3rem',
  uspFontSize: 'var(--text-font-size)',
  uspFontWeight: '800',
  uspFontColor: 'var(--white)',
  iconColor: 'var(--teal)',
  samblaStyleUSPDisplay: 'none',
  prefixIconDisplay: 'block',
  suffixIconDisplay: 'none',
};
