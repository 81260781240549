import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { LocationProvider } from '@reach/router';
import styled, { css } from 'styled-components';
import devices from '../../../../../js/styles/Devices';

import Form from '../../../../common/Form';
import Disclaimer from '../../../../utils/Disclaimer/Disclaimer';

const FormBlockWrap = styled.div`
  && {
    margin: auto;
    position: relative;
    overflow-x: hidden;
    background: var(--white);
    text-align: left;
    border-radius: 30px;
    padding-top: 0;
    max-width: 70rem;

    @media ${devices.upFromTablet} {
      width: 60rem;
    }

    ${(props) => props.lead && css`
      max-width: 36rem;
      box-shadow: var(--form-shadow);
    `}
  }
`;

const FormFrame = styled.div`
  && {
    margin: auto;

    @media ${devices.downFromMobile} {
      width: 100%;
    }
  }
`;

const FormBlock = ({
  show,
  forwardRef,
  exampleLoan,
  title,
  partnerForm,
  prefilledApplicationForm,
  prefilledValues,
  subtitle,
  type,
  aboveFold,
  ...rest
}) => {
  if (!show) return null;

  const leadForm = type[0] === 'lead' || type[0] === 'loanCalculator';

  return (
    <div>
      <FormBlockWrap
        ref={forwardRef}
        id="form"
        lead={leadForm}
      >
        <FormFrame>
          <LocationProvider>
            {(context) => (
              <>
                <Form
                  key="form-block"
                  exampleLoan={exampleLoan}
                  title={title}
                  subtitle={subtitle}
                  location={context.location}
                  partnerForm={partnerForm}
                  prefilledApplicationForm={prefilledApplicationForm}
                  prefilledValues={prefilledValues}
                  type={type[0]}
                  {...rest}
                />
              </>
            )}
          </LocationProvider>
        </FormFrame>
        {!leadForm && (
        <Disclaimer />
        )}
      </FormBlockWrap>
    </div>
  );
};

FormBlock.propTypes = {
  show: PropTypes.bool,
  disclaimer: PropTypes.string,
  inputSource: PropTypes.string,
  advisaSource: PropTypes.string,
  forwardRef: PropTypes.shape(),
  exampleLoan: PropTypes.shape(),
  title: PropTypes.string,
  partnerForm: PropTypes.bool,
  prefilledApplicationForm: PropTypes.bool,
  prefilledValues: PropTypes.shape(),
  subtitle: PropTypes.string,
  type: PropTypes.arrayOf(PropTypes.string),
  aboveFold: PropTypes.bool,
};

FormBlock.defaultProps = {
  show: true,
  type: [''],
  disclaimer: null,
  inputSource: '',
  advisaSource: '',
  aboveFold: false,
  forwardRef: null,
  exampleLoan: null,
  title: null,
  partnerForm: false,
  prefilledApplicationForm: false,
  prefilledValues: {},
  subtitle: null,
};

export default FormBlock;

export const formBlockQuery = graphql`
  fragment formBlockFields on Form {
    show
    type
    title
    subtitle
    type
    inputSource
    advisaSource
  }
`;
