import createMarkup from '../../../../../../js/utils/formatters/html-to-text-formatter';

const createConfig = (acf, style) => (
  {
    general: {
      wrapperPosition: 'relative',
      hero: {
        backgroundColor: acf.sectionSettings.backgroundColor,
        fullBackgroundImage: 'url(https://fi-rahoitu-production-cms-uploadbucket-hknz7wb2bzom.s3.amazonaws.com/uploads/2023/07/Rahoitu_image_hero-1.jpg)',
        fullMobileBackgroundImage: 'url(https://fi-rahoitu-production-cms-uploadbucket-hknz7wb2bzom.s3.amazonaws.com/uploads/2023/07/Rahoitu_image_hero-1.jpg)',
      },
    },
    columns: [
      {
        components: [
          {
            component: 'Text',
            acf: {},
            props: {
              fontWeight: style.textBlockDesktopFontWeight,
              fontSizeTitle: style.textBlockDesktopFontSizeTitle,
              dangerouslySetInnerHTML: createMarkup(acf.pitchText),
              showComponent: 'return evaluationValues.desktop',
            },
          },
          {
            component: 'Text',
            acf: {},
            props: {
              fontWeight: style.textBlockDesktopFontWeight,
              fontSizeTitle: style.textBlockDesktopFontSizeTitle,
              dangerouslySetInnerHTML: createMarkup(acf.pitchTextMobile),
              showComponent: 'return evaluationValues.mobile',
            },
          },

        ],
      },
      {
        components: [

          {
            component: 'FormBlock',
            acf: { ...acf.form },
            props: {
              color: style.formBlockColor,
              aboveFold: true,
            },
          },
        ],
      },
    ],
  }
);

export default createConfig;
